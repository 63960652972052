import PropTypes from 'prop-types';
import {useState} from 'react';
import OlarkLoader from './OlarkLoader';

export default function PlaceitOlark({className, children}) {
  const [canLoad, setCanLoad] = useState(false);

  const labelClickHandler = () => {
    setCanLoad(true);
  };

  const onLoad = () => {
    window.olark && window.olark('api.box.expand');
  };

  return (
    <>
      <button type="button" className={className} onClick={labelClickHandler}>
        {children}
      </button>
      {canLoad && <OlarkLoader onLoad={onLoad} />}
    </>
  );
}

PlaceitOlark.defaultProps = {
  className: '',
  children: null,
};

PlaceitOlark.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element,
};

export const PlasmicData = {
  name: 'OlarkTrigger',
  props: {
    children: 'slot',
  },
  importPath: './components/Olark/OlarkTrigger',
  isDefaultExport: true,
};
