/**
 * This component is a proxy for the original PlasmicImg
 * and modifies its source so it points to Cloudinary.
 * We replace PlasmicImg instances with this component via Polish AST.
 */
import {PlasmicImg} from '@plasmicapp/react-web';
import PropTypes from 'prop-types';
import useAvifSupport from '../hooks/useAvifSupport';

const svgRegex = /\.svg$/;
export default function PlasmicImgWrapper({src, ...props}) {
  const isString = typeof src === 'string';
  const target = isString ? src : src.src;
  const isSvg = svgRegex.test(target);
  const cloudinaryURL = `https://res.cloudinary.com/placeit-commercial/image/upload/f_auto/q_auto/v1/plasmic${target}`;
  const isRelativePath = target.startsWith('/');
  const isCandidate = ['main', 'staging'].includes(process.env.GIT_BRANCH);
  const featureFlag = true;
  const shouldReplace = isRelativePath && !isSvg && isCandidate && featureFlag;

  // Custom hook that indicates if Avif image format is supported by browser.
  const avifSupported = useAvifSupport();

  // If "src" is a PNG (ends with ".png," ) replace it with ".avif" or ".webp" format and add compression 40
  if (shouldReplace && (target.endsWith('.png') || target.endsWith('.webp'))) {
    const quality = 'q_40';
    const format = avifSupported ? 'avif' : 'webp';

    const baseReplacement = cloudinaryURL
      .replace(/q_auto/, quality)
      .replace(/f_auto/, `f_${format}`)
      .replace(/\.png$|\.webp$/, `.${format}`);

    src = isString ? baseReplacement : {...src, src: `${baseReplacement}`};
  } else if (shouldReplace) {
    const baseReplacement = cloudinaryURL.replace(/q_auto/, 'q_auto:eco');
    src = isString ? baseReplacement : {...src, src: `${baseReplacement}`};
  }
  return <PlasmicImg {...props} src={src} />;
}

PlasmicImgWrapper.propTypes = {
  src: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      src: PropTypes.string.isRequired,
    }),
  ]).isRequired,
};

PlasmicImgWrapper.defaultProps = {};
