import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import {useCallback, useRef} from 'react';

import useMutationObservable from '../../hooks/useMutationObservable';

const Olark = dynamic(() => import('react-olark'), {ssr: false});

const SITE_ID = process.env.NEXT_PUBLIC_OLARK_SITE_ID;

export default function OlarkLoader({onLoad}) {
  const olarkRef = useRef();

  useMutationObservable(olarkRef.current, useCallback(onMutation, []));

  function onMutation(mutationList) {
    const mutationRecord = mutationList[0];
    // Olark lib doesn't have a way to notify us when the client has loaded, other than:
    if (mutationRecord?.target?.classList.contains('olark-loaded')) {
      onLoad?.call();
    }
  }

  if (!SITE_ID) {
    return null;
  }

  return (
    <div className="hidden" ref={olarkRef}>
      <Olark siteId={SITE_ID} />;
    </div>
  );
}

OlarkLoader.defaultProps = {
  onLoad: () => {},
};

OlarkLoader.propTypes = {
  onLoad: PropTypes.func,
};
